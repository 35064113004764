// DataTable.tsx
import React, { useEffect, useState } from "react";

import {
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";

import {
  DataTableToolbar,
  DataTableFiltersResult,
  DataTableHead,
  DataTableRow,
  DataTableNoData,
  DataTablePagination,
} from ".";
import { TableSkeleton, useTable } from "../../minimal/table";
import useApiServices from "api/useApiServices";
import _ from "lodash";
import GeneralForm from "../general-form/general-form";
import { IFormProps } from "types/form-props";
import CustomBreadcrumbs from "components/minimal/custom-breadcrumbs";
import RoundedButton from "../button/rounded-button";
import { routes } from "routes/routes";
import { IFormOperation } from "types/form-operation";

const TableHeadStyles = {
  // Add backgroundColor for table head
  backgroundColor: "#DFE3E8",
  /* Apply sticky positioning to the table head */
  position: "sticky!important",
  top: 0,
  zIndex: 1,
};

const TablePaginationStyles = {
  // Set backgroundColor for table pagination
  backgroundColor: "#DFE3E8",
};

interface DataTableProps {
  tableName: string;
  parentTableName?: string;
  deleteTitle: string;
  getAllLink?: string; //TODO not optional after refactor
  getItemLink?: string; //not optional after refactor
  deleteRowLink?: string; //not optional after refactor
  createItemLink?: string; //not optional after refactor
  updateItemLink?: string; //not optional after refactor
  formSubmitMapper?: (data: any) => any;
  formDataMapper?: (data: any) => any;
  mainCol: string;
  table?: any; // Remove after refactor
  tableCols: any[]; // Replace 'any' with the actual type for table head
  tableData?: any[]; // Remove after refactor
  filters?: any; // Adjust the type according to your data structure
  resetFilters?: boolean;
  denseHeight?: number;
  emptyRows?: number;
  notFound?: boolean;
  handleFilters?: (name: string, value: any) => void;
  handleResetFilters?: () => void;
  // handleAddRow:() => void;
  handleEditRow?: (id: string, row: any) => void;
  handleDeleteRow?: (id: string) => void;
  handleViewRow?: (id: string, row: any) => void;
  handleChangeState?: (id: string, state: boolean) => void;
  handleApprovalState?: (id: string, approval: string) => void;
  onSelectRow?: (id: string, selectionMode: string) => void;
  // onSelectAllRows ,
  hasSearch?: boolean;
  hasCreate?: boolean;
  hasEdit?: boolean;
  hasDelete?: boolean;
  hasView?: boolean;
  hasOptions?: boolean;
  selectionMode?: "single" | "multiple";
  hasSelections?: boolean;
  formType?: string;
  FormContent?: React.FunctionComponent<IFormProps>;
  formSchema?: any;
}

const DataTable: React.FC<DataTableProps> = ({
  tableName,
  parentTableName,
  deleteTitle, //TODO refactor with datatable name are ather (this lable to single item title)
  mainCol,
  tableCols,
  getAllLink,
  getItemLink,
  deleteRowLink,
  createItemLink,
  updateItemLink,
  formSubmitMapper,
  formDataMapper,
  tableData: tableDataOld,
  filters,
  resetFilters,
  emptyRows,
  notFound,
  handleFilters,
  handleResetFilters,
  // handleAddRow,
  handleEditRow,
  handleDeleteRow: handleDeleteRowOld,
  handleViewRow,
  handleChangeState,
  handleApprovalState,
  onSelectRow,
  hasSearch = true,
  hasCreate,
  hasEdit = true, // TODO delete init true
  hasDelete = true,
  hasView = false,
  hasOptions = false,
  selectionMode = "single", // Default to single selection mode
  hasSelections = false,
  formType,
  FormContent,
  formSchema,
}) => {
  //FIXME
  //TODO
  //BUG

  const table = useTable();
  const { useGetListApi, useDeleteApi } = useApiServices();
  const [tableData, setTableData] = useState(tableDataOld ?? []);
  const [isLoading, setLoading] = useState(true);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState();
  const [formOperation, setFormOperation] = useState<IFormOperation>("Create");

  useEffect(() => {
    tableDataOld && setTableData(tableDataOld);
    tableDataOld && setLoading(false);
  }, [tableDataOld]); //TODO remove after refactor

  useGetListApi({
    url: getAllLink ?? "", //TODO remove condition after refactor
    onSuccess: (data: any) => {
      setTableData(data.items);
      setLoading(false);
    },
    enabled: Boolean(getAllLink),
  });

  const { mutate: handleDeleteRow } = useDeleteApi({
    url: deleteRowLink ?? "", //TODO remove condition after refactor
    onSuccess: (data: any) => {
      console.log(data);
      const deleteRow = tableData.filter((row: any) => row.id !== data.id);
      setTableData(deleteRow);

      // table.onUpdatePageDeleteRow(dataInPage.length); //TODO
    },
  });

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleUpdateForm = (row: any) => {
    setCurrentItem(row);
    setFormOperation("Update");
    setOpenForm(true);
  };

  const handleViewForm = (row: any) => {
    setCurrentItem(row);
    setFormOperation("View");
    setOpenForm(true);
  };

  const generalForm = () => (
    <GeneralForm //TODO check to props optional
      currentItem={currentItem}
      FormContent={FormContent}
      handleClose={handleCloseForm}
      formSubmitMapper={formSubmitMapper}
      formDataMapper={formDataMapper}
      setOpenForm={setOpenForm}
      formOperation={formOperation}
      formSchema={formSchema}
      getItemLink={getItemLink}
      createItemLink={createItemLink}
      updateItemLink={updateItemLink}
    />
  );

  const renderForm = (): JSX.Element => {
    switch (formType) {
      default:
      case "modal":
        return (
          <>
            <Dialog
              open={openForm}
              onClose={handleCloseForm}
              maxWidth="md"
              fullWidth
            >
              <DialogTitle>{formOperation + " " + deleteTitle}</DialogTitle>
              <DialogContent>{generalForm()}</DialogContent>
            </Dialog>
          </>
        );
    }
  };

  return (
    <div>
      {/* Bread Crumb + Create Button */}
      {parentTableName && (
        //TODO add condition only on create button
        <div className="flex items-center justify-between pb-3 h-12">
          <CustomBreadcrumbs
            links={[
              {
                name: parentTableName,
                //  href: `routes.${tableName}` //TODO add href
              },
              {
                name: tableName,
                // href: routes.parents//TODO add href
              },
            ]}
          />
          {hasCreate && (
            <RoundedButton
              label={"New" + " " + deleteTitle}
              backgroundColor="#637381"
              onClick={() => {
                setOpenForm(true);
                setFormOperation("Create");
                setCurrentItem(undefined);
              }}
              icon="mingcute:add-line"
            />
          )}
        </div>
      )}

      <Card sx={{ borderRadius: "2rem" }}>
        {/* table toolbar for table name and search text-field and export options */}
        <DataTableToolbar
          tableName={tableName}
          // filters={filters}
          // onFilters={handleFilters}
          hasSearch={hasSearch}
        />
        {renderForm()}
        {/* reset filters bar */}
        {/* {resetFilters && (
          <DataTableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            onResetFilters={handleResetFilters}
            results={tableData.length}
            sx={{ p: 2.5, py: 0 }}
          />
        )} */}

        {/* table */}
        <TableContainer
          sx={{
            position: "relative",
            overflow: "auto",
            maxHeight: "57vh",
            minHeight: "57vh",
          }}
        >
          <Table size={table.dense ? "small" : "medium"} sx={{ minWidth: 960 }}>
            {/* table head (table columns) */}
            <DataTableHead
              order={table.order}
              orderBy={table.orderBy}
              headLabel={tableCols}
              rowCount={tableData.length}
              numSelected={table.selected.length}
              onSort={table.onSort}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row: any) => row.id)
                )
              }
              selectionMode={selectionMode}
              sx={TableHeadStyles}
            />

            {/* table body */}
            <TableBody>
              {isLoading ? (
                [...Array(table.rowsPerPage)].map((i, index) => (
                  <TableSkeleton key={index} sx={{ height: 80 }} />
                ))
              ) : tableData.length > 0 ? (
                tableData
                  .slice(
                    table.page * table.rowsPerPage,
                    table.page * table.rowsPerPage + table.rowsPerPage
                  )
                  .map((row: any, index: number) => (
                    <DataTableRow
                      key={row.id || index} // Use row.id if available, otherwise use index
                      row={row}
                      selected={table.selected.includes(row.id)}
                      onSelectRow={() => onSelectRow?.(row.id, selectionMode)}
                      onDeleteRow={() =>
                        deleteRowLink
                          ? handleDeleteRow?.(row.id)
                          : handleDeleteRowOld?.(row.id)
                      } //TODO delete coondition
                      onEditRow={() =>
                        handleEditRow
                          ? handleEditRow?.(row.id, row)
                          : handleUpdateForm(row)
                      }
                      onViewRow={() =>
                        handleViewRow
                          ? handleViewRow?.(row.id, row)
                          : handleViewForm(row)
                      }
                      onListCellClick={
                        (state: boolean) => {}
                        // handleChangeState(row.id, state)
                      }
                      onListOptionClick={(approval: string) =>
                        handleApprovalState?.(row.id, approval)
                      }
                      columns={tableCols}
                      deleteTitle={deleteTitle}
                      mainCol={mainCol}
                      selectionMode={selectionMode}
                      hasSelections={hasSelections}
                      hasEdit={hasEdit}
                      hasDelete={hasDelete}
                      hasView={hasView}
                      hasOptions={hasOptions}
                    />
                  ))
              ) : (
                <DataTableNoData notFound />
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <DataTablePagination
          count={tableData.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          dense={table.dense}
          onChangeDense={table.onChangeDense}
          sx={TablePaginationStyles}
        />
      </Card>
      {/* </Container> */}
    </div>
  );
};

export default DataTable;
