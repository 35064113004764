import { STORY_REPORT_COLUMNS } from "./story-report-column";
import DataTable from "../../../components/common/data-table/data-table";
import PreviewDialog from "./story-report-form";

interface StoryReportProps {}
const StoryReport: React.FC<StoryReportProps> = () => {
  return (
    <div>
      <DataTable
        tableName="Story Reports"
        parentTableName="Overview"
        deleteTitle="Story Report"
        mainCol="title"
        getAllLink="/api/admin/user-stories" //TODO add "api" to base rout
        deleteRowLink="/api/admin/user-stories" //TODO add "api" to base rout
        getItemLink="/api/admin/user-stories" //TODO add "api" to base rout
        updateItemLink="/api/admin/user-stories" //TODO add "api" to base rout
        tableCols={STORY_REPORT_COLUMNS}
        FormContent={PreviewDialog}
        // formSchema={ParentSchema}
        hasSearch={false}
        hasEdit={false}
        hasDelete={false}
        hasView={true}
        formDataMapper={(data: any) => {
          return {
            ...data,
            username: data?.user?.fullName,
            mediaType: data?.mediaType?.label,
          };
        }}
      />
      {/* <PreviewDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        rowData={rowData}
      /> */}
    </div>
  );
};

export default StoryReport;
