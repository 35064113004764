export const STORY_REPORT_COLUMNS = [
  { id: "storyId", label: "Story Id", width: "18%" },
  {
    id: "userStory",
    label: "Story Username",
    width: "18%",
    isObjObj: true,
    child: "user",
    child2: "username",
  },
  {
    id: "user",
    label: "Reporting Username",
    width: "18%",
    isObj: true,
    child: "username",
  },
  { id: "createdAt", label: "Reporting Date", width: "22%", isDate: true },
  { id: "reason", label: "Reason", width: "35%" },
  { id: "actions", width: "22%" },
];
