import RHFRadioGroup from "components/minimal/hook-form/rhf-radio-group";
import { IFormProps } from "types/form-props";
import { RADIO_Status_OPTIONS } from "./challenge-suggestions-column";
import Image from "components/minimal/image";
import { ASSETS_API, ASSETS_API_PREFIX } from "config-global";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { fDate } from "utils/format-time";

interface ChallengeSuggestionFormProps extends IFormProps {}

const ChallengeSuggestionForm: React.FC<ChallengeSuggestionFormProps> = ({
  currentItem,
  formOperation,
}) => {
  const renderImageField = (imageUrl: string) => (
    <Image
      ratio="1/1"
      alt="gallery"
      src={ASSETS_API + ASSETS_API_PREFIX + imageUrl}
      sx={{
        borderRadius: 1,
        width: "100%",
        maxHeight: "100%",
        border: "1px",
        padding: 2,
      }}
      effect="opacity"
    />
  );

  const renderVideoField = (videoUrl: string) => {
    return (
      <iframe
        src={`https://iframe.mediadelivery.net/embed/160614/${videoUrl}?autoplay=false&loop=false&muted=false&preload=true&responsive=true`}
        title={`${videoUrl}`}
        loading="lazy"
        style={{
          position: "absolute",
          top: 0,
          height: "32px",
          width: "100%",
          padding: 8,
          borderRadius: 24,
        }}
        allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
        allowFullScreen
      ></iframe>
    );
  };

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Challenge Suggestion Information" />
          <Divider />
          <CardContent>
            <Stack spacing={1} direction={{ md: "row", xs: "column" }}>
              <Typography fontWeight="bold">Name: </Typography>
              <Typography>{currentItem?.title}</Typography>
            </Stack>
            <Stack spacing={1} direction={{ md: "row", xs: "column" }}>
              <Typography fontWeight="bold">Category: </Typography>
              <Typography>{currentItem?.challengeCategory?.title}</Typography>
            </Stack>
            <Stack spacing={1} direction={{ md: "row", xs: "column" }}>
              <Typography fontWeight="bold">User Name: </Typography>
              <Typography>{currentItem?.user?.fullName}</Typography>
            </Stack>
            <Stack spacing={1} direction={{ md: "row", xs: "column" }}>
              <Typography fontWeight="bold">Description: </Typography>
              <Typography>{currentItem?.description}</Typography>
            </Stack>
            <Stack spacing={1} direction={{ md: "row", xs: "column" }}>
              <Typography fontWeight="bold">Media Type: </Typography>
              <Typography>{currentItem?.type?.label}</Typography>
            </Stack>
            <Stack spacing={1} direction={{ md: "row", xs: "column" }}>
              <Typography fontWeight="bold">Published: </Typography>
              <Typography>
                {currentItem?.published ? "True" : "False"}
              </Typography>
            </Stack>
            <Stack spacing={1} direction={{ md: "row", xs: "column" }}>
              <Typography fontWeight="bold">Created Date: </Typography>
              <Typography>{fDate(currentItem?.createdAt)}</Typography>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <RHFRadioGroup
          label="Status"
          name="status"
          options={RADIO_Status_OPTIONS()}
          sx={{ display: "flex", flexDirection: "row" }}
        />
      </Grid>
      <Grid item xs={12}>
        {currentItem.type?.label === "Image" && currentItem?.mediaUrl
          ? renderImageField(currentItem.mediaUrl)
          : renderVideoField(currentItem.mediaUrl)}
      </Grid>
    </>
  );
};

export default ChallengeSuggestionForm;
