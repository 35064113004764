import { Box, Grid, Typography } from "@mui/material";
import Image from "../../../components/minimal/image";
import { ASSETS_API, ASSETS_API_PREFIX } from "../../../config-global";
import { IFormProps } from "types/form-props";
import { RHFRoundedTextField } from "components/minimal/hook-form";
import RHFDatePicker from "components/minimal/hook-form/rhf-date-picker";

interface PreviewDialogProps extends IFormProps {}

const PreviewDialog: React.FC<PreviewDialogProps> = ({
  // open,
  // onClose,
  currentItem: rowData,
}) => {
  const renderImageField = (imageUrl: string) => (
    <Image
      ratio="1/1"
      alt="gallery"
      src={ASSETS_API + ASSETS_API_PREFIX + imageUrl}
      sx={{
        borderRadius: 1,
        width: "100%",
        maxHeight: "100%",
        border: "1px",
        padding: 2,
      }}
      effect="opacity"
    />
  );

  const renderVideoField = (videoUrl: string) => {
    return (
      <iframe
        src={`https://iframe.mediadelivery.net/embed/160614/${videoUrl}?autoplay=false&loop=false&muted=false&preload=true&responsive=true`}
        title={`${videoUrl}`}
        loading="lazy"
        style={{
          position: "absolute",
          top: 0,
          height: "100%",
          width: "100%",
          padding: 8,
          borderRadius: 24,
        }}
        allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
        allowFullScreen
      ></iframe>
    );
  };

  return (
    <>
      <Grid item xs={6}>
        <RHFRoundedTextField name="username" label="User Name" disabled />
      </Grid>
      <Grid item xs={6}>
        <RHFRoundedTextField name="category" label="Category" disabled />
      </Grid>
      <Grid item xs={6}>
        <RHFRoundedTextField name="mediaType" label="Media Type" disabled />
      </Grid>
      <Grid item xs={6}>
        <RHFRoundedTextField name="reason" label="Reason" disabled />
      </Grid>
      <Grid item xs={6}>
        <RHFDatePicker name="createdAt" label="Created Date" disabled />
      </Grid>

      <Grid item xs={12}>
        {rowData &&
          (rowData.mediaType?.label === "Image"
            ? renderImageField(rowData.mediaUrl)
            : renderVideoField(rowData.mediaUrl))}
      </Grid>
    </>
  );
};

export default PreviewDialog;
