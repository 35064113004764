import { IFormProps } from "types/form-props";

interface UserReportFormProps extends IFormProps {}

const UserReportForm: React.FC<UserReportFormProps> = ({
  currentItem,
  formOperation,
}) => {
  return <></>;
};

export default UserReportForm;
