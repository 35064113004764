import { Controller, useFormContext } from "react-hook-form";
// @mui
import { TextFieldProps } from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  label?: string;
  isRequired?: boolean;
  minDate?: Date;
  maxDate?: Date;
  dateAndTime?: boolean;
};

export default function RHFDatePicker({
  name,
  label,
  isRequired = true,
  helperText,
  type,
  minDate,
  maxDate,
  dateAndTime,
  ...other
}: Props) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {!dateAndTime ? (
              <DatePicker
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  other.onChange && other.onChange(e);
                }}
                value={
                  !(typeof field.value === "string")
                    ? field.value
                    : new Date(field.value) || null
                }
                slotProps={{
                  textField: {
                    error: !!error,
                    fullWidth: true,
                    helperText: error ? error?.message : helperText,
                  },
                }}
                minDate={minDate!}
                maxDate={maxDate!}
                label={label}
              />
            ) : (
              <DateTimePicker
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  other.onChange && other.onChange(e);
                }}
                value={
                  !(typeof field.value === "string")
                    ? field.value
                    : new Date(field.value) || null
                }
                slotProps={{
                  textField: {
                    error: !!error,
                    fullWidth: true,
                    helperText: error ? error?.message : helperText,
                  },
                }}
                minDateTime={minDate!}
                maxDateTime={maxDate!}
              />
            )}
          </LocalizationProvider>
        );
      }}
    />
  );
}
