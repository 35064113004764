import { CHALLENGE_SUGGESTION_COLUMNS } from "./challenge-suggestions-column";
import DataTable from "../../../components/common/data-table/data-table";
import ChallengeSuggestionForm from "./challenge-suggestion-form";

interface ChallengeSuggestionProps {}

const ChallengeSuggestion: React.FC<ChallengeSuggestionProps> = () => {
  return (
    <div>
      <DataTable
        tableName="Challenge Suggestions"
        parentTableName="Overview"
        deleteTitle="Challenge Suggestion"
        mainCol="title"
        tableCols={CHALLENGE_SUGGESTION_COLUMNS}
        getAllLink="/api/admin/challenges?user_id[isNull]=true" //TODO add "api" to base rout
        deleteRowLink="/api/admin/challenges" //TODO add "api" to base rout
        getItemLink="/api/admin/challenges" //TODO add "api" to base rout
        updateItemLink="/api/admin/challenges" //TODO add "api" to base rout
        hasView={true}
        FormContent={ChallengeSuggestionForm}
        hasEdit={false}
        hasDelete={false}
        formDataMapper={(data: any) => {
          return {
            ...data,
            // challengeCategory: data?.challengeCategory.title,
            // type: data?.type.label,
            // username: data?.user?.fullName,
            // mediaType: data?.mediaType?.label,
          };
        }}
      />
    </div>
  );
};

export default ChallengeSuggestion;
