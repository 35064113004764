export const USER_REPORT_COLUMNS = [
  {
    id: "user",
    label: "Username",
    width: "25%",
    isObj: true,
    child: "username",
  },
  {
    id: "reportUser",
    label: "Reporter Username",
    width: "25%",
    isObj: true,
    child: "username",
  },
  { id: "createdAt", label: "Report Date", width: "25%", isDate: true },
  { id: "reason", label: "Reason", width: "25%" },
  { id: "actions", width: "25%" },
];
