export const Children_COLUMNS = [
  {
    id: "fullName",
    label: "Name",
    width: "18%",
    isAvatar: true,
    son: "childInfo",
    child: "avatarFileUrl",
  },
  { id: "username", label: "Username", width: "18%" },
  {
    id: "childInfo",
    label: "Parent Phone Number",
    width: "18%",
    isObjObj: true,
    child: "parent",
    child2: "phoneNumber",
  },
  {
    id: "childInfo",
    label: "Parent Email",
    width: "24%",
    isObjObj: true,
    child: "parent",
    child2: "email",
  },
  { id: "createdAt", label: "Date of Join", width: "12%", isDate: true },
  { id: "actions", width: "10%" },
];

export const Children_STATUS_OPTIONS = [
  { value: "active", label: "Active" },
  { value: "pending", label: "Pending" },
  { value: "banned", label: "Banned" },
  { value: "rejected", label: "Rejected" },
];
